<template>
  <div>
    <card-component style="overflow: visible" :title="title">
      <section class="section px-0 pt-3">
        <form ref="form" @submit.prevent="download">

          <div v-if="date_filter" class="columns">
            <div v-if="date_field_filter" class="column">
              <b-field label="Filter By">
                <b-select :required="$store.state.dates.dateMin!==null" expanded v-model="date_field"
                          placeholder="Which date to filter by">
                  <option class="is-capitalized" v-for="dateType in date_fields" :key="dateType.value"
                          :value="dateType.value">
                    {{ dateType.name }} date
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column">
              <b-field :type="required_filters.includes('start')&&$store.state.dates.dateMin===null?'is-danger':null"
                       :label="`Select start date ${date_type!=null?`(${date_type})`:''}`">
                <b-datepicker

                    :years-range="[-100,100]"
                    v-model="$store.state.dates.dateMin"
                    placeholder="Click to select..."
                    :icon="$tc('icons.calendar')"
                ></b-datepicker>
              </b-field>
            </div>
            <div class="column">
              <b-field :type="required_filters.includes('end')&&$store.state.dates.dateMax===null?'is-danger':null"
                       :label="`Select end date ${date_type!=null?`(${date_type})`:''}`">
                <b-datepicker

                    :years-range="[-100,100]"
                    v-model="$store.state.dates.dateMax"
                    placeholder="Click to select..."
                    :icon="$tc('icons.calendar')"
                ></b-datepicker>
              </b-field>
            </div>
          </div>
          <div class="columns is-multiline">
            <div v-if="course_filter" class="column is-6">
              <b-field :label="$tc('Course',1)">
                <!--suppress HtmlUnknownAttribute -->
                <b-autocomplete
                    :required="required_filters.includes('course')"

                    v-debounce:300ms="getFilteredCourse"
                    :data="filteredCourses"
                    :icon="$tc('icons.user')"
                    autocomplete="off"
                    v-model="courseHold"
                    :placeholder="$tc('Course',1) + ' Name'"
                    name="Course"
                    :loading="loadingCourses"
                    field="name"
                    :clear-on-select="false"
                    @select="option => selectCourse(option)"
                >
                  <template slot-scope="props">
                    <p>{{ props.option.code }} | {{ props.option.name }}</p>
                  </template>
                </b-autocomplete>
              </b-field>
              <p
                  v-if="courseHold.length>0 && course_id == null && !loadingCourses && submitted"
                  class="has-text-danger is-size-7"
              >No course selected</p>
            </div>
            <div v-if="user_filter" class="column is-4">
              <b-field label="User">
                <UsersFilter
                    :campus_id="campus_id"
                    @selected="option=>user_id = option.id"
                    @cleared="user_id=null"
                    :type="'autocomplete'"
                    placeholder="Filter by user"
                />
              </b-field>
            </div>
            <div v-if="owner_filter" class="column is-4">
              <b-field label="Owner">
                <b-select expanded v-model="owner_id" placeholder="Filter by owner">
                  <option :value="null">All</option>
                  <option v-for="user in admissions_team" :key="user.id" :value="user.id">
                    {{ user.first_name }} {{ user.last_name }} | {{ user.email }}
                  </option>
                </b-select>
              </b-field>
            </div>

            <div v-if="creator_filter" class="column is-4">
              <b-field label="Creator">
                <b-select expanded v-model="creator_id" placeholder="Filter by creator">
                  <option :value="null">All</option>
                  <option v-for="user in admissions_team" :key="user.id" :value="user.id">
                    {{ user.first_name }} {{ user.last_name }} | {{ user.email }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div v-if="user_type_filter" class="column is-4">
              <b-field label="User type">
                <b-select expanded v-model="user_type" placeholder="Filter by user type">
                  <option :value="null">All</option>
                  <option :value="'learner'">{{ $tc('Learner', 1) }}</option>
                  <option :value="'staff'">Staff</option>


                </b-select>
              </b-field>
            </div>
            <div v-if="status_filter" class="column is-4">
              <b-field expanded label="Status">
                <b-dropdown expanded v-model="status_id" multiple aria-role="list">
                  <b-button class="select" :class="{'is-empty':status_id.length===0}" expanded slot="trigger">
                    <span>Select Statuses</span>

                  </b-button>

                  <b-dropdown-item
                      :key="status.id" v-for="status in statuses" :value="status.id"
                      aria-role="listitem"
                  >
                    <span>{{ status.name }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-field>
            </div>
            <div v-if="statuses_range_filter" class="column is-4">
              <b-field label="From Status">
                <b-select expanded v-model="from_status_id" placeholder="Filter by status">
                  <option :value="null">All</option>

                  <option :key="status.id" v-for="status in statuses" :value="status.id">{{ status.name }}
                  </option>

                </b-select>
              </b-field>
            </div>
            <div v-if="statuses_range_filter" class="column is-4">
              <b-field label="To Status">
                <b-select expanded v-model="to_status_id" placeholder="Filter by status">
                  <option :value="null">All</option>

                  <option :key="status.id" v-for="status in statuses" :value="status.id">{{ status.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div v-if="campus_filter" class="column is-4">
              <b-field label="Campus">
                <b-select expanded v-model="campus_id" :required="required_filters.includes('campus')"
                          placeholder="Filter by campus">
                  <option :value="null">All</option>

                  <option :key="campus.id" v-for="campus in campuses" :value="campus.id">{{ campus.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div v-if="manager_filter" class="column is-4">
              <b-field label="Manager">
                <b-select :loading="loadingManagers" expanded v-model="manager_id" placeholder="Filter by manager">
                  <option :value="null">All</option>
                  <option v-for="user in admissions_team" :key="user.id" :value="user.id">
                    {{ user.first_name }} {{ user.last_name }} | {{ user.email }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div v-if="department_filter" class="column is-4">
              <b-field label="Department">
                <b-select expanded v-model="department_id" :required="required_filters.includes('department')"
                          placeholder="Filter by department">
                  <option :value="null">All</option>

                  <option :key="department.id" v-for="department in departments" :value="department.id">
                    {{ department.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div v-if="employment_type_filter" class="column is-4">
              <b-field expanded label="Employment Type">
                <b-select :required="required_filters.includes('employment_type')"
                          expanded v-model="employment_type" placeholder="Filter by employment type">
                  <option :value="null">All</option>

                  <option v-for="type in $store.state.staffProfile.employment_types" :key="type.id" :value="type.name">
                    {{ type.name }}
                  </option>
                </b-select>
              </b-field>
            </div>

            <div v-if="phase_filter" class="column is-4">
              <b-field label="Phase">
                <b-select :loading="loadingPhases" :disabled="!campus_id" expanded placeholder="Filter by phase"
                          v-model="phase_id">
                  <option :value="null">All</option>
                  <option
                      v-for="phase in phases"
                      :key="phase.id"
                      :value="phase.id"
                  >{{ phase.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div v-if="stages_filter" class="column is-4">
              <b-field label="Stage">
                <b-select :loading="loadingStages" :disabled="!phase_id" expanded placeholder="Filter by stage"
                          v-model="stage_id">
                  <option :value="null">All</option>
                  <option
                      v-for="stage in stages"
                      :key="stage.id"
                      :value="stage.id"
                  >{{ stage.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div v-if="homeclasses_filter" class="column is-4">
              <b-field label="Home Class">
                <b-select :loading="loadingHomeclasses" :disabled="!phase_id" expanded
                          placeholder="Filter by home class"
                          v-model="home_class_id">
                  <option :value="null">All</option>
                  <option
                      v-for="homeclass in homeclasses"
                      :key="homeclass.id"
                      :value="homeclass.id"
                  >{{ homeclass.name }}
                  </option>
                </b-select>
              </b-field>
            </div>

            <div v-if="subjects_filter" class="column is-4">
              <b-field label="Subject">
                <b-select :required="required_filters.includes('subject')" :loading="loadingSubjects"
                          :disabled="!campus_id" expanded placeholder="Filter by subject"
                          v-model="subject_id">
                  <option :value="null">All</option>
                  <option
                      v-for="subject in subjects"
                      :key="subject.id"
                      :value="subject.id"
                  >{{ subject.long_name }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div v-if="classgroups_filter" class="column is-4">
              <b-field label="Class Group">
                <b-select :required="required_filters.includes('class_group')" :loading="loadingClassGroups"
                          :disabled="!subject_id" expanded
                          placeholder="Filter by class group"
                          v-model="class_group_id">
                  <option :value="null">All</option>
                  <option
                      v-for="classGroup in classGroups"
                      :key="classGroup.id"
                      :value="classGroup.id"
                  >{{ classGroup.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div v-if="extramurals_filter" class="column is-4">
              <b-field label="Extramural">
                <b-select :loading="loadingExtramurals" :disabled="!campus_id" expanded
                          placeholder="Filter by extramural"
                          v-model="extramural_id">
                  <option :value="null">All</option>
                  <option
                      v-for="extramural in extramurals"
                      :key="extramural.id"
                      :value="extramural.id"
                  >{{ extramural.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div v-if="assessment_type_filter" class="column is-4">
              <b-field expanded label="Assessment Type">
                <b-select expanded :required="required_filters.includes('assessment_type')"
                          v-model="assessment_type_id">
                  <option :value="null">All</option>
                  <option :value="1">Task</option>
                  <option :value="2">Project</option>
                  <option :value="3">Formal</option>
                  <option :value="4">Exam</option>
                </b-select>

              </b-field>
            </div>

            <div v-if="services_filter" class="column is-4">
              <b-field label="Service">
                <b-select :loading="loadingServices" :disabled="!campus_id" expanded placeholder="Filter by service"
                          v-model="service_id">
                  <option :value="null">All</option>
                  <option
                      v-for="service in services"
                      :key="service.id"
                      :value="service.id"
                  >{{ service.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div v-if="covid_symptoms_filter" class="column is-4">
              <b-field label="Symptoms">
                <b-select expanded placeholder="Filter by symptoms"
                          v-model="symptoms">
                  <option :value="null">All</option>
                  <option :value="1">Has symptoms</option>
                  <option :value="0">No symptoms</option>

                </b-select>
              </b-field>
            </div>
            <div v-if="temperature_filter" class="column is-4">
              <b-field label="Temperature">
                <b-select expanded placeholder="Filter by temperature"
                          v-model="temperature">
                  <option :value="null">All</option>
                  <option :value="1">Has a temperature of 38 or higher</option>
                  <option :value="0">Has a temperature of less than 38</option>

                </b-select>
              </b-field>
            </div>
            <div v-if="year_filter" class="column is-4">
              <b-field label="Year">
                <b-select
                    :required="required_filters.includes('year')"
                    expanded
                    v-model="$store.state.dates.year"
                    placeholder="Filter by year"
                >
                  <option v-if="can_all_years" :value="null">All</option>

                  <option
                      v-for="year of $store.getters['dates/years']"
                      :key="year + 'year'"
                      :value="year"
                  >{{ year }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div v-if="packages_filter" class="column is-4">
              <b-field label="Package">
                <b-select :loading="loadingPackages" :disabled="!campus_id" expanded placeholder="Filter by package"
                          v-model="package_id">
                  <option :value="null">All</option>
                  <option
                      v-for="item in packages"
                      :key="item.id"
                      :value="item.id"
                  >{{ item.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div v-if="semesters.length>0" class="column is-4">
              <b-field label="Semester">
                <b-select
                    expanded
                    v-model="$store.state.dates.semester_id"
                    placeholder="Filter by semester"
                >
                  <option :value="null">All</option>

                  <option v-for="semester in semesters" :key="semester.id" :value="semester.id">
                    >{{ semester.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div v-if="term_filter" class="column is-4">
              <b-field expanded label="Term">

                <b-select :loading="loadingTerms" :required="required_filters.includes('term')"
                          placeholder="Select Term" :disabled="campus_id===null"
                          expanded v-model="term_id" aria-role="list">
                  <optgroup :label="group.name" :key="index" v-for="(group,index) in terms"
                  >
                    <option v-for="term in group.children" :key="term.id" :value="term.id">{{ term.name }}</option>

                  </optgroup>

                </b-select>


              </b-field>
            </div>
            <div v-if="focus_term_filter" class="column is-4">
              <b-field expanded label="Report Focus Term">

                <b-select :loading="loadingTerms" :required="required_filters.includes('focus_term')"
                          :disabled="campus_id===null"
                          placeholder="Select Focus Term" expanded v-model="focus_term_id" aria-role="list">
                  <optgroup :label="group.name" :key="index" v-for="(group,index) in terms"
                  >
                    <option v-for="term in group.children" :key="term.id" :value="term.id">{{ term.name }}</option>

                  </optgroup>

                </b-select>


              </b-field>
            </div>
            <div v-if="application_stages_filter" class="column is-4">
              <b-field label="Application Stage">
                <b-select expanded placeholder="Filter by stage"
                          v-model="application_stage_id">
                  <option :value="null">All</option>
                  <option
                      v-for="stage in applications_stages"
                      :key="stage.id"
                      :value="stage.id"
                  >{{ stage.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div v-if="victory_filter" class="column is-4">
              <b-field grouped>
                <b-field label="Show Won">
                  <b-checkbox v-model="is_won" :false-value="null" :true-value="true">

                  </b-checkbox>
                </b-field>
                <b-field label="Show Lost">
                  <b-checkbox v-model="is_lost" :false-value="null" :true-value="true">

                  </b-checkbox>
                </b-field>
              </b-field>
            </div>
            <div v-if="delivery_types.length>0" class="column is-4">
              <b-field label="Delivery Type">
                <b-select expanded v-model="delivery_type_id" placeholder="Filter by delivery type">
                  <option :value="null">All</option>
                  <option
                      v-for="delivery_type in delivery_types"
                      :key="delivery_type.id"
                      :value="delivery_type.id"
                  >
                    {{ delivery_type.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div v-if="stages_range_filter" class="column is-4">
              <b-field label="From Stage">
                <b-select expanded v-model="from_stage_id" placeholder="Filter by stage">
                  <option :value="null">All</option>
                  <option v-for="stage in stages" :key="stage.id" :value="stage.id">
                    {{ stage.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div v-if="stages_range_filter" class="column is-4">
              <b-field label="To Stage">
                <b-select expanded v-model="to_stage_id" placeholder="Filter by stage">
                  <option :value="null">All</option>
                  <option v-for="stage in stages" :key="stage.id" :value="stage.id">
                    {{ stage.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div v-if="existing_filter" class="column is-4">
              <b-field label="Existing Students">
                <b-checkbox
                    v-model="existing"
                    :false-value="0"
                    :true-value="1"
                >
                  Show only returning students
                </b-checkbox>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="Export File Type">
                <b-select expanded v-model="export_file_type" placeholder="Export type">
                  <option v-for="type in file_types" :key="type.name" :value="type.value">
                    {{ type.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Actions">
                <b-field grouped>
                  <b-field v-if="inline_modules_filter"
                  >
                    <b-checkbox
                        v-model="inline_modules"
                        :true-value="1"
                        :false-value="null"
                    >Inline Modules
                    </b-checkbox>
                  </b-field>
                  <b-field v-if="include_guardians_option"
                  >
                    <b-checkbox
                        v-model="include_guardians"
                        :true-value="1"
                        :false-value="null"
                    >Include Guardians
                    </b-checkbox>
                  </b-field>
                  <b-field v-if="inline_services_option"
                  >
                    <b-checkbox
                        :disabled="!campus_id"
                        v-model="include_services"
                        :true-value="1"
                        :false-value="null"
                    >Inline Services
                    </b-checkbox>
                  </b-field>
                  <b-field v-if="to_total_filter"
                  >
                    <b-checkbox
                        v-model="to_total"
                        :true-value="1"
                        :false-value="null"
                    >Total
                    </b-checkbox>
                  </b-field>
                  <b-field>
                    <b-button
                        icon-right="table"
                        :loading="loading"
                        native-type="submit"
                        @click="download_state=false;"
                    >Generate
                    </b-button>

                    <b-button
                        icon-right="download"
                        :loading="loading"
                        native-type="submit"
                        @click="download_state=true; "
                    >Download
                    </b-button>

                    <b-button
                        v-if="admissions_team.length>0"
                        :icon-right="$tc('icons.close-circle')"
                        @click="clear()"
                    >Clear
                    </b-button>
                  </b-field>
                </b-field>
              </b-field>
            </div>
          </div>

        </form>
      </section>
    </card-component>
    <card-component v-if="tableData.length>0" title="Table view">
      <div class="bar-container">
        <div class="flippy">
          <PerfectScrollbarWrapper>
          <b-table
              ref="table"
              class="mt-3 is-fullwidth"
              :data="tableData"
              :striped="true"
              :hoverable="true"
              :bordered="false"
              :scrollable="false"
              narrowed
          >
            <template #empty>
              <div class="has-text-centered">No records</div>
            </template>
            <template>
              <b-table-column
                  :cell-class="'is-size-7'"
                  :header-class="'is-size-7'"
                  v-slot="props"
                  sortable
                  :field="name"
                  v-for="(name) in columns"
                  :key="name"
                  :label="name"
                  centered
              >{{ props.row[name] }}
              </b-table-column>
            </template>
          </b-table>
          </PerfectScrollbarWrapper>
        </div>
      </div>
    </card-component>
  </div>
</template>


<script>
import CardComponent from "@/components/layout/CardComponent";
import axios from "axios";
import Phase from "@/models/Phase";
import Stage from "@/models/Stage";
import Service from "@/models/Service";
import Subject from "@/models/Subject";
import Extramural from "@/models/Extramural";
import HomeClass from "@/models/HomeClass";
import {mapState} from "vuex";
import Package from "@/models/Package";

import Term from "@/models/Term";
import ClassGroup from "@/models/ClassGroup";
import UsersFilter from "@/components/panelled-dash/UsersFilter";
import User from "@/models/User";
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";

export default {
  name: "export",
  components: {
    CardComponent, UsersFilter, PerfectScrollbarWrapper
  },
  props: {
    type: {
      type: String,
      default() {
        return 'exports'
      }
    },
    required_filters: {
      type: Array,
      default: () => {
        return [];
      },
    },
    owner_filter: {type: Boolean, default: false},
    user_filter: {type: Boolean, default: false},
    creator_filter: {type: Boolean, default: false},
    manager_filter: {type: Boolean, default: false},
    campus_filter: {type: Boolean, default: false},
    department_filter: {type: Boolean, default: false},
    employment_type_filter: {type: Boolean, default: false},
    existing_filter: {type: Boolean, default: false},
    course_filter: {type: Boolean, default: false},
    inline_modules_filter: {type: Boolean, default: false},
    inline_services_option: {type: Boolean, default: false},
    include_guardians_option: {type: Boolean, default: false},
    date_field_filter: {type: Boolean, default: false},
    to_total_filter: {type: Boolean, default: false},
    module_filter: {type: Boolean, default: false},
    stages_filter: {type: Boolean, default: false},
    covid_symptoms_filter: {type: Boolean, default: false},
    temperature_filter: {type: Boolean, default: false},
    term_filter: {type: Boolean, default: false},
    focus_term_filter: {type: Boolean, default: false},
    packages_filter: {type: Boolean, default: false},
    phase_filter: {type: Boolean, default: false},
    services_filter: {type: Boolean, default: false},
    extramurals_filter: {type: Boolean, default: false},
    assessment_type_filter: {type: Boolean, default: false},
    subjects_filter: {type: Boolean, default: false},
    classgroups_filter: {type: Boolean, default: false},
    homeclasses_filter: {type: Boolean, default: false},
    stages_range_filter: {type: Boolean, default: false},
    victory_filter: {
      type: Boolean,
      default: false
    },
    status_filter: {type: Boolean, default: false},
    user_type_filter: {type: Boolean, default: false},
    statuses_range_filter: {type: Boolean, default: false},
    year_filter: {type: Boolean, default: false},
    can_all_years: {type: Boolean, default: true},
    application_stages_filter: {type: Boolean, default: false},
    date_filter: {type: Boolean, default: true},
    entities: {type: String, required: true},
    date_type: {type: String, default: () => null},
    date_fields: {type: Array, default: () => []},
    statuses: {type: Array, default: () => []},
    applications_stages: {type: Array, default: () => []},
    semesters: {type: Array, default: () => []},
    delivery_types: {type: Array, default: () => []},
    campuses: {type: Array, default: () => []},
    departments: {type: Array, default: () => []},
    admissions_team: {type: Array, default: () => []},
    title: {type: String, required: true},
    file_types: {
      type: Array,
      default() {
        return [{name: "csv", value: 'csv'}, {name: "xls", value: 'xls'}, {name: "xlsx", value: "xlsx"}]
      }
    },
    default_type_value: {
      type: String, default() {
        return 'csv'
      }
    }
  },
  data() {
    return {
      download_state: false,
      submitted: false,
      loadingModules: false,
      module_id: null,
      tableData: [],
      filteredModules: [],
      moduleHold: "",
      courseHold: "",
      loadingCourses: false,
      course_id: null,
      filteredCourses: [],
      campus_id: null,
      user_type: null,
      department_id: null,
      employment_type: null,
      phase_id: null,
      subject_id: null,
      extramural_id: null,
      assessment_type_id: null,
      service_id: null,
      semester_id: null,
      home_class_id: null,
      class_group_id: null,
      status_id: [],
      term_id: null,
      focus_term_id: null,
      loadingPhases: null,
      loadingPackages: null,
      loadingStages: null,
      loadingServices: null,
      loadingSubjects: null,
      loadingTerms: null,
      symptoms: null,
      temperature: null,
      loadingExtramurals: null,
      loadingHomeclasses: null,
      loadingClassGroups: null,
      delivery_type_id: null,
      date_field: null,
      stage_id: null,
      package_id: null,
      application_stage_id: null,
      owner_id: null,
      user_id: null,
      manager_id: null,
      include_guardians: null,
      inline_modules: null,
      include_services: null,
      to_total: null,
      creator_id: null,
      existing: 0,
      from_stage_id: null,
      to_stage_id: null, from_status_id: null,
      to_status_id: null,
      loading: false,
      export_file_type: this.default_type_value,
      is_won: null,
      is_lost: null,
      loadingManagers: false

    };
  },
  watch: {
    campus_id(newValue) {
      this.manager_id = null
      if (this.manager_filter) {
        this.loadingManagers = true
        User.FetchAll({page: 1, limit: 999}, {
          user_permission: 'manage all staff in campus', ...(this.campus_id
              ? {
                campus_id: this.campus_id,
              }
              : {})
        }).catch(err => {
          this.handleError(err)
        }).finally(() => {
          this.loadingManagers = false
        })
      }
      if (newValue !== null) {
        if (this.phase_filter) {
          this.loadingPhases = true
          Phase.FetchAll({page: 1, limit: 999}, {
            campus_id: parseInt(newValue), ...(this.$store.state.dates.year
                ? {
                  year: this.$store.state.dates.year,
                }
                : {})
          }).then(() => {
            this.loadingPhases = false
          })
        }
        if (this.packages_filter) {
          this.loadingPackages = true
          Package.FetchAll({page: 1, limit: 999}, {
            campus_id: parseInt(newValue), ...(this.$store.state.dates.year
                ? {
                  year: this.$store.state.dates.year,
                }
                : {})
          }).then(() => {
            this.loadingPackages = false
          })
        }
        if (this.services_filter) {
          this.loadingPhases = true
          Service.FetchAll({page: 1, limit: 999}, {
            campus_id: parseInt(newValue), ...(this.$store.state.dates.year
                ? {
                  year: this.$store.state.dates.year,
                }
                : {})
          }).then(() => {
            this.loadingPhases = false
          })
        }
        if (this.term_filter || this.focus_term_filter) {
          this.loadingTerms = true
          Term.FetchAll({page: 1, limit: 999}, {
            campus_id: parseInt(newValue), ...(this.$store.state.dates.year
                ? {
                  year: this.$store.state.dates.year,
                }
                : {})
          }).then(() => {
            this.loadingTerms = false
          })
        }
        if (this.subjects_filter) {
          this.loadingSubjects = true
          Subject.FetchAll({page: 1, limit: 999}, {
            campus_id: parseInt(newValue), ...(this.$store.state.dates.year
                ? {
                  year: this.$store.state.dates.year,
                }
                : {})
          }).then(() => {
            this.loadingSubjects = false
          })
        }
        if (this.homeclasses_filter) {
          this.loadingHomeclasses = true
          HomeClass.FetchAll({page: 1, limit: 999}, {
            campus_id: parseInt(newValue), ...(this.$store.state.dates.year
                ? {
                  year: this.$store.state.dates.year,
                }
                : {})
          }).then(() => {
            this.loadingHomeclasses = false
          })
        }
        if (this.extramurals_filter) {
          this.loadingExtramurals = true
          Extramural.FetchAll({page: 1, limit: 999}, {
            campus_id: parseInt(newValue), ...(this.$store.state.dates.year
                ? {
                  year: this.$store.state.dates.year,
                }
                : {})
          }).then(() => {
            this.loadingExtramurals = false
          })
        }
      } else {
        this.include_services = null
      }
    },
    year() {
      if (this.phase_filter) {
        this.loadingPhases = true
        Phase.FetchAll({page: 1, limit: 999}, {
          campus_id: this.campus_id, ...(this.$store.state.dates.year
              ? {
                year: this.$store.state.dates.year,
              }
              : {})
        }).then(() => {
          this.loadingPhases = false
        })
      }
      if (this.packages_filter) {
        this.loadingPackages = true
        Package.FetchAll({page: 1, limit: 999}, {
          campus_id: this.campus_id, ...(this.$store.state.dates.year
              ? {
                year: this.$store.state.dates.year,
              }
              : {})
        }).then(() => {
          this.loadingPackages = false
        })
      }
      if (this.services_filter) {
        this.loadingPhases = true
        Service.FetchAll({page: 1, limit: 999}, {
          campus_id: this.campus_id, ...(this.$store.state.dates.year
              ? {
                year: this.$store.state.dates.year,
              }
              : {})
        }).then(() => {
          this.loadingPhases = false
        })
      }
      if (this.subjects_filter) {
        this.loadingSubjects = true
        Subject.FetchAll({page: 1, limit: 999}, {
          campus_id: this.campus_id, ...(this.$store.state.dates.year
              ? {
                year: this.$store.state.dates.year,
              }
              : {})
        }).then(() => {
          this.loadingSubjects = false
        })
      }
      if (this.homeclasses_filter) {
        this.loadingHomeclasses = true
        HomeClass.FetchAll({page: 1, limit: 999}, {
          campus_id: this.campus_id, ...(this.$store.state.dates.year
              ? {
                year: this.$store.state.dates.year,
              }
              : {})
        }).then(() => {
          this.loadingHomeclasses = false
        })
      }
      if (this.extramurals_filter) {
        this.loadingExtramurals = true
        Extramural.FetchAll({page: 1, limit: 999}, {
          campus_id: this.campus_id, ...(this.$store.state.dates.year
              ? {
                year: this.$store.state.dates.year,
              }
              : {})
        }).then(() => {
          this.loadingExtramurals = false
        })
      }

    },
    phase_id(newValue) {
      if (newValue !== null) {
        this.loadingStages = true
        Stage.FetchAll({page: 1, limit: 999}, {
          phase_id: parseInt(newValue), ...(this.$store.state.dates.year
              ? {
                year: this.$store.state.dates.year,
              }
              : {}),
        }).then(() => {
          this.loadingStages = false
        })

      }
    },
    subject_id(newValue) {
      if (newValue === null) {
        this.class_group_id = null

      } else {
        this.loadingClassGroups = true
        ClassGroup.FetchAll({page: 1, limit: 999}, {
          subject_id: this.subject_id
        }).then(() => {
          this.loadingClassGroups = false
        })
      }
    },
  },
  computed: {
    ...mapState('dates', ['year']),
    columns() {
      if (this.tableData) {
        let item = this.tableData[0]
        return Object.keys(item)
      }
      return []
    },
    terms() {
      return Term.query()
          .where('campus_id', this.campus_id).where('parent_id', null).where(term => {
            return this.$store.state.dates.year ? new Date(term.start).getFullYear() === this.$store.state.dates.year : true
          })
          .with('children').orderBy('start').get()
    },
    phases() {
      return Phase.query().where('campus_id', this.campus_id).where(item => {
        return this.$store.state.dates.year ? item.year === this.$store.state.dates.year : true
      }).get()
    }
    ,
    classGroups() {
      return ClassGroup.query().where('subject_id', this.subject_id).get()

    },
    stages() {
      return Stage.query().where('phase_id', this.phase_id).where(item => {
        return this.$store.state.dates.year ? item.year === this.$store.state.dates.year : true
      }).get()
    }, packages() {
      return Package.query().where('campus_id', this.campus_id).where(item => {
        return this.$store.state.dates.year ? item.year === this.$store.state.dates.year : true
      }).get()
    },
    subjects() {
      return Subject.query().where('campus_id', this.campus_id).where(item => {
        return this.$store.state.dates.year ? item.year === this.$store.state.dates.year : true
      }).get()
    }, homeclasses() {
      return HomeClass.query().where('phase_id', this.phase_id).where(item => {
        return this.$store.state.dates.year ? item.year === this.$store.state.dates.year : true
      }).get()
    },
    extramurals() {
      return Extramural.query().where('campus_id', this.campus_id).where(item => {
        return this.$store.state.dates.year ? item.year === this.$store.state.dates.year : true
      }).get()
    },
    services() {
      return Service.query().where('campus_id', this.campus_id).where(item => {
        return this.$store.state.dates.year ? item.year === this.$store.state.dates.year : true
      }).get()
    }
  },
  methods: {
    // getFilteredModule(text) {
    //   if (text.length > 0) {
    //     this.loadingModules = true;
    //
    //     Module.search(text, true, 1).then((result) => {
    //       this.loadingModules = false;
    //       if (result.entities) {
    //         this.filteredModules = result.entities.modules;
    //       } else {
    //         this.filteredModules = [];
    //       }
    //     });
    //   }
    // },
    // getFilteredCourse(text) {
    //   if (text.length > 0) {
    //     this.loadingCourses = true;
    //
    //     Course.search(text, true, 1).then((result) => {
    //       this.loadingCourses = false;
    //       if (result.entities) {
    //         this.filteredCourses = result.entities.courses;
    //       } else {
    //         this.filteredCourses = [];
    //       }
    //     });
    //   }
    // },
    selectModule(module) {
      if (module) {
        this.module_id = module.id;
      } else {
        this.module_id = null;
      }
    },
    selectCourse(course) {
      if (course) {
        this.course_id = course.id;
      } else {
        this.course_id = null;
      }
    },
    clear() {
      this.status_id = [];
      this.delivery_type_id = null;
      this.stage_id = null;
      this.application_stage_id = null;
      this.$store.state.dates.semester_id = null;
      this.$store.state.dates.year = null;
      this.owner_id = null;
      this.user_id = null;
      this.creator_id = null;
      this.course_id = null;
      this.courseHold = "";
      this.module_id = null;
      this.inline_modules = null;
      this.include_services = null;
      this.to_total = null;
      this.moduleHold = "";
      this.existing = false;
      this.to_stage_id = null;
      this.from_stage_id = null;
      this.to_status_id = null;
      this.from_status_id = null;
      this.symptoms = null;
      this.temperature = null;
      this.manager_id = null;
      this.campus_id = null;
      this.department_id = null;
      this.employment_type = null;
      this.phase_id = null;
      this.user_type = null;
      this.package_id = null;
      this.subject_id = null;
      this.service_id = null;
      this.extramural_id = null;
      this.home_class_id = null;
      this.class_group_id = null;
      this.include_guardians = null;
      this.date_field = null;
      this.is_won = null;
      this.is_lost = null;
      this.term_id = null;
      this.focus_term_id = null;
      this.assessment_type_id = null;
    },
    download() {

      if (this.required_filters.includes('start') && this.$store.state.dates.dateMin === null) {
        this.$store.dispatch('toast/createToast', {type: 'is-danger', message: 'The start date is required'})
        return;
      }
      if (this.required_filters.includes('end') && this.$store.state.dates.dateMax === null) {
        this.$store.dispatch('toast/createToast', {type: 'is-danger', message: 'The end date is required'})
        return;
      }
      let action = this.download_state;
      this.submitted = true;

      if (
          this.moduleHold.length > 0 &&
          this.module_id == null &&
          !this.loadingModules
      ) {
        return;
      }
      if (
          this.courseHold.length > 0 &&
          this.course_id == null &&
          !this.loadingCourses
      ) {
        return;
      }
      this.$store.dispatch("loader/show");
      this.loading = true;
      axios({
        url: `${process.env.VUE_APP_API_URL}/${this.type}/${this.entities}`, //your url
        method: "GET",

        params: {
          ...(this.$store.state.dates.dateMin && this.date_filter
              ? {
                start: this.$store.getters["dates/formattedDateMin"],
              }
              : {}),
          ...(this.$store.state.dates.dateMax && this.date_filter
              ? {
                end: this.$store.getters["dates/formattedDateMax"],
              }
              : {}),

          ...(this.statuses.length > 0
              ? {
                status_id: this.status_id,
              }
              : {}),
          ...(this.creator_id
              ? {
                creator_id: this.creator_id,
              }
              : {}), ...(this.manager_id
              ? {
                manager_id: this.manager_id,
              }
              : {}),
          ...(this.term_id
              ? {
                term_id: this.term_id,
              }
              : {}),
          ...(this.focus_term_id
              ? {
                focus_term_id: this.focus_term_id,
              }
              : {}),
          ...(this.date_field
              ? {
                date_field: this.date_field,
              }
              : {}),
          ...(this.assessment_type_id
              ? {
                assessment_type_id: this.assessment_type_id,
              }
              : {}),
          ...(this.campus_id
              ? {
                campus_id: this.campus_id,
              }
              : {}),
          ...(this.department_id
              ? {
                department_id: this.department_id,
              }
              : {}),
          ...(this.employment_type
              ? {
                employment_type: this.employment_type,
              }
              : {}),
          ...(this.phase_id
              ? {
                phase_id: this.phase_id,
              }
              : {}),
          ...(this.symptoms !==null
              ? {
                symptoms: this.symptoms,
              }
              : {}), ...(this.temperature !==null
              ? {
                temperature: this.temperature,
              }
              : {}),
          ...(this.existing_filter
              ? {
                existing: this.existing,
              }
              : {}),
          ...(this.delivery_type_id
              ? {
                delivery_type_id: this.delivery_type_id,
              }
              : {}),
          ...(this.owner_id
              ? {
                owner_id: this.owner_id,
              }
              : {}),
          ...(this.user_id
              ? {
                user_id: this.user_id,
              }
              : {}),
          ...(this.user_type
              ? {
                user_type: this.user_type,
              }
              : {}),
          ...(this.course_id
              ? {
                course_id: this.course_id,
              }
              : {}),
          ...(this.module_id
              ? {
                module_id: this.module_id,
              }
              : {}),
          ...(this.stage_id
              ? {
                stage_id: this.stage_id,
              }
              : {}), ...(this.package_id
              ? {
                package_id: this.package_id,
              }
              : {}),
          ...(this.application_stage_id
              ? {
                application_stage_id: this.application_stage_id,
              }
              : {}),
          ...(this.service_id
              ? {
                service_id: this.service_id,
              }
              : {}),
          ...(this.subject_id
              ? {
                subject_id: this.subject_id,
              }
              : {}),
          ...(this.home_class_id
              ? {
                home_class_id: this.home_class_id,
              }
              : {}),
          ...(this.class_group_id
              ? {
                class_group_id: this.class_group_id,
              }
              : {}),
          ...(this.extramural_id
              ? {
                extramural_id: this.extramural_id,
              }
              : {}),
          ...(this.from_stage_id
              ? {
                from_stage_id: this.from_stage_id,
              }
              : {}),
          ...(this.to_stage_id
              ? {
                to_stage_id: this.to_stage_id,
              }
              : {}), ...(this.from_status_id
              ? {
                from_status_id: this.from_status_id,
              }
              : {}),
          ...(this.to_status_id
              ? {
                to_status_id: this.to_status_id,
              }
              : {}),
          ...(this.inline_modules
              ? {
                inline_modules: this.inline_modules,
              }
              : {}), ...(this.include_services
              ? {
                include_services: this.include_services,
              }
              : {}), ...(this.include_guardians
              ? {
                include_guardians: this.include_guardians,
              }
              : {}),
          ...(this.to_total
              ? {
                to_total: this.to_total,
              }
              : {}), ...(this.victory_filter && this.is_won !== null
              ? {
                is_won: this.is_won,
              }
              : {}),
          ...(this.victory_filter && this.is_lost !== null
              ? {
                is_lost: this.is_lost,
              }
              : {}),
          ...(this.$store.state.dates.year && this.year_filter
              ? {
                year: this.$store.state.dates.year,
              }
              : {}),
          ...(this.$store.state.dates.semester_id
              ? {
                semester: this.$store.state.dates.semester_id,
              }
              : {}), ...(action === true
              ? (this.export_file_type ? {
                export_file_type: this.export_file_type,
              } : {})
              : {export_file_type: 'csv'}),

          // ...(this.stage_id && this.entities != "registrations"
          //   ? {
          //       stage_id: this.stage_id
          //     }
          //   : {})
        },
        headers: {
          "Content-Type": "application/json",

          Authorization: "Bearer " + this.$store.state.auth.accessToken,
          "X-Requested-With": "XMLHttpRequest",
        },
      })
          .then((response) => {
            this.tableData = response.data.data.attributes.data;
            if (action === true) {
              const link = document.createElement("a");
              link.href = response.data.exportUrl;
              document.body.appendChild(link);
              link.click();
            }
            this.$store.dispatch("loader/hide");
            this.submitted = false;
            this.loading = false;
          })
          .catch((err) => {
            this.$store.dispatch("toast/createToast", {
              message: JSON.stringify(err.response.data),
            });
          });
    },
    oneWeekAgo() {
      var oneWeekAgo = new Date();
      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
      return oneWeekAgo;
    },
  },
};
</script>


<style lang="scss">

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.bar-container {
  max-width: 99%;
  overflow-y: hidden;
}

.flippy {
  max-width: 100%;
}

.bar-container,
.flippy {
  transform: rotateX(180deg);
  -moz-transform: rotateX(180deg); /* Mozilla */
  -webkit-transform: rotateX(180deg); /* Safari and Chrome */
  -ms-transform: rotateX(180deg); /* IE 9+ */
  -o-transform: rotateX(180deg); /* Opera */
}

</style>
