<template>
  <b-field>
    <b-input
        v-if="type==='filter'"
        v-model="searchTerm"
        v-debounce:300ms="getFiltered"
        :disabled="disabled"
        :icon="$tc('icons.users')"
        :icon-right="$tc('icons.close-circle')"
        :icon-right-clickable="true"
        :loading="loading"
        :placeholder="placeholder"
        v-on:icon-right-click="clear()"
    ></b-input>
    <b-autocomplete
        v-if="type==='autocomplete'"
        :id="new Date()"
        ref="autocomplete"
        v-model="searchTerm"
        v-debounce:300ms="getFiltered"
        :append-to-body="append"
        :clear-on-select="clearOnSelect"
        :clearable="!disabled"
        :custom-formatter="thing=> name(thing)"
        :data="searchResponse"
        :disabled="disabled"
        :dropdown-position="dropdownDirection"
        :icon="$tc('icons.users')"
        :loading="loading"
        :placeholder="placeholder"
        autocomplete="off"
        @select="option => select(option)"
    >
      <template slot-scope="props">
        <p>{{ props.option.first_name }} {{ props.option.last_name }}</p>
      </template>
    </b-autocomplete>
  </b-field>
</template>

<script>
import User from "@/models/User";

export default {
  name: "UsersFilter",
  data: function () {
    return {
      loading: false,
      searchTerm: '',
      searchResponse: null,
      activeUserId: null
    };
  },
  props: {
    disabled: {
      type: Boolean,
      default() {
        return false
      }
    },
    persistSearch: {
      type: Boolean,
      default() {
        return true
      }
    },
    append: {
      type: Boolean,
      default() {
        return true
      }
    },
    clearOnSelect: {
      type: Boolean,
      default() {
        return false
      }
    },
    dropdownDirection: {
      type: String,
      default() {
        return 'auto'
      }
    },
    role: {
      type: String, default() {
        return null
      }
    },
    with_permission: {
      type: String,
      default() {
        return null
      }
    },
    placeholder: {
      type: String,
      default() {
        return 'User'
      }
    },
    without_roles: {
      type: Array, default() {
        return []
      }
    },
    selected_user_id: {
      type: Number, required: false
    },
    type: {
      type: String, default() {
        return 'filter'
      }
    },
    campus_id: {
      type: Number, default() {
        return null
      }
    }, phase_id: {
      type: Number, default() {
        return null
      }
    },
    stage_id: {
      type: Number, default() {
        return null;
      }
    }, status_id: {
      type: Number, default() {
        return null;
      }
    }, year: {
      type: Number, default() {
        return null;
      }
    }
  },
  watch: {
    selected_user_id(newValue) {
      if (newValue !== null) {
        if (this.activeUserId !== this.selected_user_id) {
          User.FetchById(this.selected_user_id).then(({entities}) => {
            this.searchResponse = entities.users
            this.$refs.autocomplete.setSelected(entities.users[0])

          })
        }
      }
    }
  },
  mounted() {
    if (this.selected_user_id) {
      if (this.activeUserId !== this.selected_user_id) {
        User.FetchById(this.selected_user_id).then(({entities}) => {
          this.searchResponse = entities.users
          this.$refs.autocomplete.setSelected(entities.users[0])

        })
      }
    }
  },
  methods: {
    name(thing) {
      return `${thing.first_name} | ${thing.last_name}`
    },
    select(option) {
      if (option === null) {
        this.clear()
      } else {
        this.activeUserId = option.id
        this.$emit('selected', option)
      }
    },

    clear() {
      this.searchTerm = ''
      this.searchResponse = [];
      this.$emit('cleared')
    },
    getFiltered(text) {
      if (text.length > 0) {
        this.loading = true;

        User.FetchAll({page: 1, limit: 100}, {
          ...{search: text}, ...(this.campus_id ? {
            campus_id: this.campus_id
          } : {}),
          ...(this.stage_id ? {
            stage_id: this.stage_id
          } : {}),
          ...(this.status_id ? {
            status_id: this.status_id
          } : {}), ...(this.year ? {
            year: this.year
          } : {}),
          ...(this.phase_id ? {
            phase_id: this.phase_id
          } : {}), ...(this.without_roles ? {
            without_roles: this.without_roles
          } : {}), ...(this.role ? {
            user_role: this.role
          } : {}), ...(this.with_permission ? {
            user_permission: this.with_permission
          } : {})
        }, [], this.persistSearch).then((result) => {
          this.loading = false;

          if (result.entities) {
            this.searchResponse = result.entities.users;
            if (this.type === 'filter') {
              this.$emit('filtered', {result: result, term: this.searchTerm})

            }
          } else if (this.persistSearch) {
            this.$emit('filtered', {term: this.searchTerm, result: result})

            this.searchResponse = [];
          } else {
            this.searchResponse = result.response.data.data.map(user => {
              return {...user, ...user.attributes}
            })

          }
        });
      } else {
        this.clear()
      }
    }
  }
}
</script>

<style scoped>

</style>
